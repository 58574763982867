.article {
  padding      : 30px;
  background   : #FFFFFF;
  border-radius: 8px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .article {
    padding : 15px;
    border-radius: 0px;
  }
}

.article-title {
  line-height   : 42px;
  font-size     : 30px;
  color         : #3C3C3C;
  letter-spacing: 0;
  font-weight   : 600;
  margin-top    : 10px;
}

.article-author-block {
  display      : flex;
  margin-bottom: 30px;
}

.article-author-block .article-author {
  margin-bottom: 6px;
}

.article-author-name {
  font-size     : 18px;
  color         : #3C3C3C;
  letter-spacing: 0;
  font-weight   : 600;
  margin-right  : 10px;
  line-height   : 24px;
  display       : inline-block;
}

.article-author-extra {
  font-size     : 13px;
  color         : #999A9A;
  letter-spacing: 0;
  font-weight   : 400;
}

.article-author .follow-btn {
  font-size: 13px;
  color    : #427DFF;
}

.article-content {
  font-size  : 16px;
  color      : #3C3C3C;
  line-height: 32px;
  font-weight: 500;
}

.article-content img{
  width: 100% !important;
}

.article-author-info{
  margin-left: 10px;
}
