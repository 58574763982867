.header {
  display: flex;
  justify-content: space-between;
}

.publishGroup {
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-right: 18px;
  align-items: flex-end;
}

.publishButton {
  border-radius: 100px;
}

.publishTime {
  font-size: 14px;
  color: #999a9a;
}

.titleInput {
  flex: 1;
  line-height: 40px;
  font-size: 28px;
  color: #3c3c3c;
  font-weight: 500;
  padding: 22px 5px 18px 30px;
  border: none !important;
  box-shadow: none !important;
}

.richText {
  border: none;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}

.richText > div:last-of-type {
  flex: auto;
}

.richText :global(.ql-toolbar) {
  background: #d9d9d9;
}

.richText :global(.ql-container) {
  border: none !important;
  height: auto;
  overflow: auto;
}

.submitBtn {
  width: 40px;
  height: 40px;
  background-color: #6783ff;
  color: white;
  border-radius: 100px;
  position: absolute;
  right: 16px;
  bottom: 16px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  padding: 0;
}

.toolbar {
  background: #e6e6e6;
  display: flex;
  justify-content: space-between;
}

.qlPublish {
  width: 100px !important;
  color: #3c3c3c;
}

.toolbarRight {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.modalContainer {
  display: flex;
  justify-content: center;
}

.imageUploader {
  width: 128px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: dashed 1px #3c3c3c;
  cursor: pointer;
}

.uploaderPlaceholder {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

:global(.ql-save) {
  width: auto !important;
  color: #3c3c3c;
}

:global(.ql-save::before) {
  content: " ";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-image: url("../../assets/save.svg");
}

:global(button.ql-save:hover::before) {
  background-image: url("../../assets/save-blue.svg");
}

:global(.ql-save::after) {
  display: inline-block;
  vertical-align: super;
  content: "保存";
}

:global(.ql-publish) {
  width: auto !important;
  color: #3c3c3c;
}

:global(.ql-publish::before) {
  content: " ";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: cover;
  position: relative;
  top: -2px;
  background-image: url("../../assets/publish.svg");
}

:global(button.ql-publish:hover::before) {
  background-image: url("../../assets/publish-blue.svg");
}

:global(.ql-publish::after) {
  display: inline-block;
  vertical-align: super;
  content: "发布";
}

:global(.ql-toolbar .ql-formats:last-of-type) {
  float: right;
}

.interviewOptionsContent {
  padding: 0px 30px 10px 30px;
}

:global(.ant-select-selector) {
  border-radius: 100px !important;
}

:global(.ant-picker) {
  border-radius: 100px !important;
}

:global(.ql-picker.ql-size) {
  width: 98px;
}

:global(.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before) {
  content: attr(data-value);
}

:global(.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]) {
  line-height: 1;
}

:global(.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before) {
  content: attr(data-value);
}

:global(.ql-picker.ql-size .ql-picker-item::before, .ql-picker-label::before) {
  content: "14px";
}

:global(.ql-snow
    .ql-picker.ql-size
    .ql-picker-item[data-value="12px"]::before) {
  font-size: 12px;
}

:global(.ql-snow
    .ql-picker.ql-size
    .ql-picker-item[data-value="14px"]::before) {
  font-size: 14px;
}

:global(.ql-snow
    .ql-picker.ql-size
    .ql-picker-item[data-value="16px"]::before) {
  font-size: 16px;
}

:global(.ql-snow
    .ql-picker.ql-size
    .ql-picker-item[data-value="18px"]::before) {
  font-size: 18px;
}

:global(.ql-snow
    .ql-picker.ql-size
    .ql-picker-item[data-value="20px"]::before) {
  font-size: 20px;
}

:global(.ql-snow
    .ql-picker.ql-size
    .ql-picker-item[data-value="24px"]::before) {
  font-size: 24px;
}

:global(.ql-snow
    .ql-picker.ql-size
    .ql-picker-item[data-value="32px"]::before) {
  font-size: 32px;
}

:global(.ql-snow
    .ql-picker.ql-size
    .ql-picker-item[data-value="48px"]::before) {
  font-size: 48px;
}

:global(.ql-snow
    .ql-picker.ql-size
    .ql-picker-item[data-value="64px"]::before) {
  font-size: 64px;
}

:global(.ql-snow
    .ql-picker.ql-size
    .ql-picker-item[data-value="72px"]::before) {
  font-size: 72px;
}

:global(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value]::before) {
  content: attr(data-value);
  font-family: attr(data-value);
}

:global(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value]::before) {
  content: attr(data-value);
  font-family: attr(data-value);
}

:global(.ql-snow
    .ql-picker.ql-font
    .ql-picker-label[data-value="SimSun"]::before),
:global(.ql-snow
    .ql-picker.ql-font
    .ql-picker-item[data-value="SimSun"]::before) {
  content: "宋体";
  font-family: "SimSun";
}

:global(.ql-snow
    .ql-picker.ql-font
    .ql-picker-label[data-value="SimHei"]::before),
:global(.ql-snow
    .ql-picker.ql-font
    .ql-picker-item[data-value="SimHei"]::before) {
  content: "黑体";
  font-family: "SimHei";
}
:global(.ql-snow
    .ql-picker.ql-font
    .ql-picker-label[data-value="Microsoft-YaHei"]::before),
:global(.ql-snow
    .ql-picker.ql-font
    .ql-picker-item[data-value="Microsoft-YaHei"]::before) {
  content: "微软雅黑";
  font-family: "Microsoft YaHei";
}
:global(.ql-snow
    .ql-picker.ql-font
    .ql-picker-label[data-value="KaiTi"]::before),
:global(.ql-snow
    .ql-picker.ql-font
    .ql-picker-item[data-value="KaiTi"]::before) {
  content: "楷体";
  font-family: "KaiTi";
}
:global(.ql-snow
    .ql-picker.ql-font
    .ql-picker-label[data-value="FangSong"]::before),
:global(.ql-snow
    .ql-picker.ql-font
    .ql-picker-item[data-value="FangSong"]::before) {
  content: "仿宋";
  font-family: "FangSong";
}

:global(.ql-font-SimSun) {
  font-family: "SimSun";
}
:global(.ql-font-SimHei) {
  font-family: "SimHei";
}
:global(.ql-font-Microsoft-YaHei) {
  font-family: "Microsoft YaHei";
}
:global(.ql-font-KaiTi) {
  font-family: "KaiTi";
}
:global(.ql-font-FangSong) {
  font-family: "FangSong";
}
:global(.ql-font-Arial) {
  font-family: "Arial";
}
:global(.ql-font-Times-New-Roman) {
  font-family: "Times New Roman";
}
:global(.ql-font-sans-serif) {
  font-family: "sans-serif";
}
