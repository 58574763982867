@media (max-width: 768px) {
  .App{
    padding-top: 96px !important;
  }
}

.App {
  display: flex;
  justify-content: center;
  padding-top: 56px;
}

.mobile-hint {
  text-align: center;
  color: orange;
  font-size: 40px;
  height: 90vh;
  line-height: 90vh;
}
