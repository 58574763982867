@media (max-width: 768px) {
    .container{
        width: 100% !important;
        padding-top: 0px !important;
    }
}
.container{
    padding-top: 15px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 960px;
}

.loadingMoreButton{
    margin-top: 50px;
}

.footer{
    text-align: center;
    margin-top: 60px;
}

.footer a{
    color: darkgray;
}
