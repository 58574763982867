.authorBlock {
  padding      : 16px;
  background   : #FFFFFF;
  border-radius: 8px;
  margin-bottom: 10px;
}

.authorBlock .articleAuthorInfo {
  flex: auto;
  margin-left: 10px;
}

.authorBlock .articleAuthorBlock {
  display      : flex;
  margin-bottom: 15px;
}

.authorBlock .articleAuthor {
  display: flex;
  width  : 100%;
}

.authorBlock .articleAuthorName {
  flex: auto;
}

.recBlock {
  margin: 9px 0;
}

.recName {
  font-size  : 14px;
  color      : #3C3C3C;
  font-weight: 600;
}

.recDesc {
  font-size  : 10px;
  color      : #999A9A;
  font-weight: 400;
}
