.backBtn {
    width        : 210px;
    height       : 42px;
    line-height  : 40px;
    font-size    : 14px;
    border       : 1px solid #8BAFFF;
    border-radius: 21px;
    text-align   : center;
    color        : #8BAFFF;
    margin       : 30px 0 22px 15px;
    cursor       : pointer;
}

.writingTypes {
    width: 100%;
}

.typeBlock {
    height   : 40px;
    padding  : 0 16px;
    font-size: 14px;
    position : relative;
    cursor: pointer;
}

.typeBlock.current {
    height    : 40px;
    background: #666666;
    padding   : 0 16px;
    font-size : 14px;
}

.typeBlock.current::before {
    content         : " ";
    background-color: #427DFF;
    display         : block;
    position        : absolute;
    left            : 0;
    top             : 0;
    width           : 4px;
    height          : 40px;
}

.typeText {
    font-size  : 14px;
    line-height: 40px;
    color      : #FFFFFF;
    font-weight: 500;
}

.bottomActions {
    position: absolute;
    bottom  : 0;
    left    : 0;
    width   : 100%;
}

.settings {
    height     : 20px;
    font-size  : 14px;
    line-height: 20px;
    margin     : 10px;
    color      : #999A9A;
}

.menu {
    margin: -12px -16px;
}

.menu>div {
    font-size   : 14px;
    line-height : 20px;
    display     : flex;
    align-items : center;
    height      : 36px;
    width       : 155px;
    padding-left: 13px;
}

.menu>div>div {
    margin-left: 10px;
    flex-grow  : 1;
}

.menu>div:not(:last-of-type) {
    border-bottom: 1px solid #D9D9D9;
}
