.footer {
  display        : flex;
  /*height         : 56px;*/
  padding        : 10px 0;
  background     : #FFFFFF;
  box-shadow     : 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  width          : 100%;
  justify-content: center;
}

.footerContent {
  flex   : 0 1 1024px;
  display: flex;
}

.footerBlock {
  display    : flex;
  align-items: center;
  margin     : 0 15px;
  font-size  : 14px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .footerBlock {
    width: 80px;
    margin: 0;
    justify-content: center;
  }

  .footerContent{
    padding: 0 15px;
  }
}

.footerBlock span {
  font-size   : 20px;
  margin-right: 8px;
}

.commentTextarea{
  width: 400px;
  border-radius: 10px;
}

.shareContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shareContent p{
  font-size: 17px;
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  .textareaForm{
    width: 60%;
  }
  .commentTextarea{
    width: 100%;
  }
}

.footerBlockGroup{
  display: flex;
}

.commentButtonGroup{
  margin-left: 10px;
  line-height: 32px;
}
