.comments {
  padding      : 30px;
  background   : #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 768px) {
  .comments {
    padding : 15px;
  }
}

.commentTextarea {
  background   : #F7F7F7;
  border       : 1px solid #E1E1E1;
  border-radius: 8px;
}

.comments .ant-form-item {
  margin-bottom: 10px
}

.commentsTitle {
  position      : relative;
  font-size     : 18px;
  color         : #3C3C3C;
  letter-spacing: 0;
  font-weight   : 500;
  padding-left  : 18px;
  line-height   : 20px;
  margin-bottom : 10px;
}

.commentsTitle::before {
  content         : " ";
  position        : absolute;
  top             : 0;
  left            : 0;
  width           : 4px;
  height          : 20px;
  display         : block;
  background-color: #427DFF;
}
