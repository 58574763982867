@media (max-width: 768px) {
    .container{
        width: 100% !important;
    }
}

.container{
    padding: 30px 0;
    width: 960px;
    border-bottom: 1px solid #F0F0F0;;
}

.title{
    font-size: 18px;
    letter-spacing: 0;
    font-weight: 600;
    margin-bottom: 8px;
}

.content{
    font-size: 12px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 400;
    margin-bottom: 13px;
}

.articleInfo{
    display: flex;
    align-items: center;
}

.comment{
    display: flex;
    align-items: center;
}

.like{
    display: flex;
    align-items: center;
    margin-left: 14px;
}

.commentLikeNumber{
    font-size: 12px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 500;
    margin-left: 4px;
}

.createdTime{
    font-size: 12px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 500;
    margin-left: 20px;
}
