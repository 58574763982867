.container{
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    width: 960px;
}

.leftContainer{
    flex: 1;
    margin-right: 40px;
}

.authArticle{
    margin-top: 20px;
}

@media (max-width: 768px) {
    .authorInfo{
        display: none;
    }

    .container{
        width: 100%;
        padding: 0 15px;
    }

    .leftContainer{
        width: 100%
    }
}
