.container{
    padding: 20px 39px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.loadingMoreButton{
    margin-top: 50px;
}
