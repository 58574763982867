.settingItem{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F0F0F0;
    padding: 20px 0;
}

.itemLeft{
    width: 120px;
}

.itemTitle{
    font-size: 15px;
    color: #969696;
}

.input{
    height: 40px;
    border-radius: 25px;
    width: 500px;
}

.codeInput{
    height: 40px;
    border-radius: 25px;
    width: 340px;
}

.saveButton{
    height: 40px;
    border-radius: 25px;
    margin-top: 40px;
    width: 100px;
}

.codeButton{
    margin-left: 15px;
    width: 140px;
}

@media (max-width: 768px) {
    .itemLeft{
        width: 80px;
    }

    .input{
        width: 70%;
    }
}
