.container{
    padding-bottom: 30px;
}

.followerInfo{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.nickname{
    font-size: 14px;
    color: #323333;
    letter-spacing: 0;
    font-weight: 500;
    margin-left: 9px;
    margin-right: 9px;
}

.likeTime{
    font-size: 14px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 500;
}

.title{
    font-size: 20px;
    color: #323333;
    letter-spacing: 0;
    font-weight: 500;
    margin-bottom: 7px;
}

.title:hover {
    color: #427DFF;
}

.content{
    font-size: 15px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.articleInfo{
    display: flex;
    margin-top: 15px;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #F0F0F0;
}

.articleAuthor{
    font-size: 12px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 500;
}

.comment{
    margin-left: 20px;
}

.like{
    margin-left: 14px;
}

.commentLikeNumber{
    font-size: 12px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 500;
    margin-left: 4px;
}
