.container{
    height: 100%;
    padding-top: 29px;
    width: 960px;
    display: flex;
}

.followingList{
    width: 280px;
    height: 100%;
    border-right: 1px solid #F0F0F0;
}

.followingListTitle{
    height: 31px;
    font-size: 14px;
    color: #3C3C3C;
    font-weight: 500;
    padding-left: 12px;
}

.articleList{
    flex: 1;
}
