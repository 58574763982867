@media (max-width: 768px) {
    .container{
        width: 100% !important;
    }

    .interviewOptionsContent{
        width: 100% !important;
        padding: 0px 15px !important;
    }
}
.container{
    padding-top: 20px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 960px;
}

.loadingMoreButton{
    margin-top: 50px;
}

.interviewOptionsContent{
    padding: 0px 30px 10px 0px;
    width: 960px;
}

:global(.ant-select-selector) {
    border-radius: 100px !important;
}

:global(.ant-picker){
    border-radius: 100px !important;
}

.searchButton{
    border-radius: 30px;
}
