.container{
    padding: 30px 0;
    border-bottom: 1px solid #F0F0F0;
}

.authorInfo{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.avatar{
    margin-right: 10px;
}

.authorInfoText{
    font-size: 12px;
    color: #999A9A;
    font-weight: 500;
    margin-left: 10px;
}

.title{
    font-size: 18px;
    letter-spacing: 0;
    font-weight: 600;
    margin-bottom: 8px;
}

.content{
    font-size: 12px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 400;
    margin-bottom: 13px;
}

.articleInfo{
    display: flex;
    align-items: center;
}

.comment{
    display: flex;
    align-items: center;
}

.like{
    display: flex;
    align-items: center;
    margin-left: 14px;
}

.commentLikeNumber{
    font-size: 12px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 500;
    margin-left: 4px;
}
