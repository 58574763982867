.notiTypes {
    border-radius: 4px;
}

.notiTypes .typeBlock {
    height          : 50px;
    display         : flex;
    font-size       : 22px;
    color           : #427DFF;
    align-items     : center;
    padding-left    : 26px;
    cursor          : pointer;
    background-color: #FFFFFF;
}

.notiTypes .typeBlock.current {
    background-color: #bbc2e9;
}

.typeText {
    line-height   : 24px;
    font-size     : 16px;
    color         : #3C3C3C;
    letter-spacing: 0;
    font-weight   : 500;
    margin-left   : 22px;
}