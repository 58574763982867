.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 16px;
}

.title {
  font-size: 21px;
  color: #3c3c3c;
  letter-spacing: 0;
  font-weight: 400;
  margin-bottom: 23px;
}

.input {
  height: 40px;
  border-radius: 20px;
  padding-left: 20px;
}

.username {
  margin-bottom: 20px;
}

.password {
  margin-bottom: 46px;
}

.signBtn {
  height: 60px;
  width: 250px;
  background: #427dff;
  border-radius: 30px;
  font-size: 21px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
}
