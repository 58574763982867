.container{
    width: 100%;
}

.menuItem{
    width: 100%;
    height: 50px;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
}

.selected{
    background-color: #f0f0f0;
}
