.recommends {
  padding      : 15px;
  background   : #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.recommendsTitle {
  font-size     : 16px;
  line-height   : 24px;
  color         : #3C3C3C;
  font-weight   : 600;
  padding-bottom: 22px;
  border-bottom : 1px solid #F0F0F0;
}

.block {
  border-bottom: 1px solid #F0F0F0;
  position     : relative;
  padding-left : 58px;
  margin-top   : 20px;
}

.block .blockHeader {
  position: absolute;
  top     : 0;
  left    : 0;
}

.block .blockTime {
  position   : absolute;
  top        : 0;
  right      : 0;
  font-size  : 14px;
  line-height: 22px;
  color      : #999A9A;
  font-weight: 500;
}

.block .blockName {
  font-size    : 14px;
  line-height  : 22px;
  color        : #3C3C3C;
  font-weight  : 500;
  margin-bottom: 3px;
}

.block .blockContent {
  font-size    : 14px;
  line-height  : 22px;
  color        : #999A9A;
  font-weight  : 400;
  margin-bottom: 6px;
}

.block .blockArticle {
  font-size    : 12px;
  line-height  : 18px;
  color        : #999A9A;
  font-weight  : 400;
  margin-bottom: 20px;

}
