.container{
    height: 60px;
    display: flex;
    padding: 10px 12px;
    align-items: center;
    cursor: pointer;
}

.selected{
    background: #F0F0F0;
}

.authorAvatar{
    width: 40px;
    height: 40px;
}

.authorName{
    font-weight: 500;
    font-size: 14px;
    color: #3C3C3C;
    margin-left: 9px;
}
