.container{
    width: 980px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textArea{
    height: 300px;
    width: 100%;
    margin-top: 50px;
}

.submitBtn{
    margin-top: 30px;
    height: 40px;
    border-radius: 20px;
    width: 120px;
}
