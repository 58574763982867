.container{
    height: 100%;
    padding-top: 29px;
    width: 960px;
    display: flex;
}

.menuList{
    width: 280px;
    height: 100%;
}

.settingPage{
    flex: 1;
    margin-left: 30px;
}

@media (max-width: 768px) {
    .container{
        width: 100%;
    }

    .menuList{
        display: none;
    }

    .settingPage{
        margin-left: 15px;
    }
}
