.container{
    height: 100%;
}

.newArticle {
    font-size     : 14px;
    line-height   : 30px;
    margin        : 10px 20px;
    color         : #3C3C3C;
    letter-spacing: 0;
    font-weight   : 500;
    cursor: pointer;
}

.newArticle>span {
    margin: 0 10px;
}

.articleBlock {
    display      : flex;
    align-items  : center;
    height       : 90px;
    border-bottom: 1px solid #D9D9D9;
    cursor: pointer;
}

.articleBlock.current {
    background: #E6E6E6;
    position  : relative;
}

.articleBlock.current::before {
    position  : absolute;
    display   : block;
    content   : " ";
    width     : 6px;
    height    : 90px;
    background: #427DFF;
    left      : 0;
    top       : 0;
}

.articleTitle {
    flex: 1 1 auto;
}

.articleTitleText {
    font-size     : 20px;
    line-height   : 30px;
    color         : #3C3C3C;
    letter-spacing: 0;
    font-weight   : 500;
}

.articleUpdateAt {
    text-align: right;
    font-size : 14px;
    color     : #bdbdbd;
}

.articleList{
    overflow: auto;
    height: calc(100% - 50px);
}
