.user{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar{
    cursor: pointer;
    height: 100%;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar:hover{
    background: #F5F5F5;
}

.userMenuButton{
    font-weight: bold;
    font-size: 14px;
    color: #3C3C3C;
    display: flex;
    align-items: center;
}

.menuIcon{
    margin-right: 14px;
    color: #427DFF;;
    font-size: 20px;
}

@media (max-width: 768px) {
    .user{
        flex: auto;
        justify-content: flex-end;
    }
}
