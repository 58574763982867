.detailMain {
  display        : flex;
  justify-content: center;
}

.detailLayout {
  flex    : 0 1 1024px;
  width: 1024px;
  padding : 10px;
}

@media (max-width: 768px) {
  .detailLayout {
    padding : 0px;
    width: 100%;
  }
}

.detailFooter {
  position: fixed;
  bottom  : 0;
  width   : 100%;
  padding : 0;
  /*height  : 56px;*/
  display : flex;
}
