.content{
  height: 56px;
  width: 100%;
  border-bottom: 1px solid #F0F0F0;
  display: flex;
  position: fixed;
  background: white;
  z-index: 1000;
}

.logo{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoTitle{
  font-size: 30px;
  font-weight: bold;
  color: #427DFF;
}

.menu{
  width: 920px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

.search{
  position: relative;
}

.search input{
  width: 240px;
  height: 40px;
  background: #EEEEEE;
  border-radius: 20px;
  padding: 0 40px 0px 15px;
  border: none;
  font-size: 14px;
}

/*.search input:focus{*/
/*  border: solid 2px #2f54eb;*/
/*}*/

.search img{
  position: absolute;
  right: 20px;
  height: 14px;
  margin-top: 13px;
}

@media (max-width: 768px) {
  .content{
    height: 56px;
    flex-direction: column;
    border-bottom: none;
  }
  .headerTop{
    display: flex;
    border-bottom: 1px solid #F0F0F0;
  }
  .search{
    line-height: 56px;
  }

  .search input{
    width: auto;
  }

  .search img{
    margin-top: 20px;
  }

  .logo{
    line-height: 56px;
    margin-left: 15px;
  }

  .mobileCategory{
    height: 40px;
    display: flex;
    background: white;
    justify-content: space-evenly;
  }

  .mobileMenuItem{
    line-height: 40px;
    padding: 0 5px;
  }
}
