@media (max-width: 768px) {
    .container{
        padding-left: 15px;
    }
}

.container{

}

.tag{
    cursor: pointer;
}
