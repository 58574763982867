.container{
    width: 274px;
}

.introduce{
    padding-bottom: 20px;
    border-bottom: 1px solid #F0F0F0;;
}

.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
}

.header div{
    font-size: 14px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 500;
}

.introTextArea{
    background: #F7F7F7;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    margin-bottom: 14px;
}

.saveBtn{
    width: 70px;
    height: 32px;
    border: 1px solid #427DFF;
    border-radius: 16px;
    font-size: 14px;
    color: #427DFF;
    letter-spacing: 0;
    font-weight: 500;
}

.cancelBtn{
    font-size: 14px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 500;
}

.linkContainer{
    padding: 20px 0px;
    border-bottom: 1px solid #F0F0F0;;
}

.linkItem{
    width: 100%;
    text-align: left;
    font-size: 14px;
    color: #3C3C3C;
    letter-spacing: 0;
    font-weight: 500;
    padding: 0;
    display: flex;
    align-items: center;
}

.linkItem:first-child{
    margin-bottom: 10px;
}
