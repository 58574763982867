.container{
    padding: 30px 0;
    border-bottom: 1px solid #F0F0F0;
}

.authorInfo{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.avatar{
    margin-right: 10px;
}

.authorInfoText{
    font-size: 12px;
    color: #999A9A;
    font-weight: 500;
    margin-left: 10px;
}

.title{
    font-size: 12px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 400;
}

.content{
    font-size: 14px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 400;
}
