.container{
    padding: 30px 0;
    border-bottom: 1px solid #F0F0F0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.authorInfo{
    display: flex;
}

.textInfo{
    margin-left: 15px;
}

.nickname{
    font-size: 16px;
    color: #3C3C3C;
    letter-spacing: 0;
    font-weight: 600;
}

.briefInfo{
    font-size: 12px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 500;
}

.likeCount{
    font-size: 12px;
    color: #999A9A;
    letter-spacing: 0;
    font-weight: 500;
}

.followingBtn{
    width: 100px;
    height: 40px;
    border-radius: 20px;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: 500;
}

.followed{
    border: 1px solid #BDBDBD;
    background: white;
    color: #BDBDBD;
}

.followerBtn{
    width: 100px;
    height: 40px;
    border-radius: 20px;
    background: #427DFF;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: 500;
}
