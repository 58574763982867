.writing {
  height : 100vh;
  display: flex;
}

.writingMenu {
  flex      : 0 0 240px;
  background: #404040;
  position  : relative;
}

.writingArticles {
  flex        : 0 0 360px;
  background  : #FFFFFF;
  border-right: 1px solid #D9D9D9;
  position    : relative;
}

.writingArticlesHidden {
  width: 0px;
  background  : #FFFFFF;
  border-right: 1px solid #D9D9D9;
  position    : relative;
}

.writingContent {
  flex      : 1 1 auto;
  background: #fff;
}

.showHideButton{
  position: absolute;
  top: 15px;
  right: -10px;
  background: white;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 1000;
}
