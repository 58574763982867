.container{
    display: flex;
    align-items: center;
}

.right{
    margin-left: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.authorName{
    font-size: 18px;
    color: #3C3C3C;
    letter-spacing: 0;
    font-weight: 600;
    margin-bottom: 8px;
}

.rightBottom{
    display: flex;
}

.infoItem{
    border-right: 1px solid #979797;
    padding-right: 8px;
    margin-right: 8px;
    cursor: pointer;
}

.infoItem:last-child{
    border-right: none;
}

.infoItemValue{
    font-size: 16px;
    color: #3C3C3C;
    letter-spacing: 0;
    font-weight: 600;
}

.infoItemLabel{
    font-size: 12px;
    color: #3C3C3C;
    letter-spacing: 0;
    font-weight: 400;
}
