.loginBtnGroup{
    display: flex;
    flex: 1;
    align-items: center;
}


@media (max-width: 768px) {
    .loginBtnGroup{
        justify-content: flex-end;
        margin-right: 15px;
    }
}
