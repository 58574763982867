.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 16px;
}

.title {
  font-size: 21px;
  color: #3c3c3c;
  letter-spacing: 0;
  font-weight: 400;
  margin-bottom: 23px;
}

.input {
  height: 40px;
  border-radius: 20px;
}

.username {
  padding-left: 20px;
  margin-bottom: 20px;
}

.password {
  padding-left: 20px;
  margin-bottom: 20px;
}

.signBtn {
  height: 60px;
  width: 250px;
  background: #427dff;
  border-radius: 30px;
  font-size: 21px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
}

.authContainer {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}

.authCode {
  padding-left: 20px;
  flex: 1;
}

.authCodeBtn {
  height: 40px;
  width: 120px;
  background: #427dff;
  border-radius: 20px;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  margin-left: 10px;
}
